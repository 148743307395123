import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import PageHeader from '~/components/PageHeader';
import BaseCardDesignEntur from '~/components/BaseCardDesignEntur';
import lightdarkmode from "./images/lightdarkmode.png";
import lightdarkmodecontrast from "./images/lightDarkmodeContrast.png";
import darkmodedemo from "./images/darkmodedemo.gif";
import componentExample from "./images/componentExample.png";
import componentExampleDark from "./images/componentExampleDark.png";
import illustrationExample from "./images/illustrationExample.png";
import illustrationExampleDark from "./images/illustrationExampleDark.png";
import { Heading4 } from '@entur/typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
    </PageHeader>
    <p>{`Både de vanlige fargene og kontrastfargene finnes i light mode(lys modus). I praksis er vanlige farger samlet under kategorien «Standard», mens kontrastfarger er samlet under kategorien «Contrast» innenfor light mode. Begge kategorier har like dark mode-farger, slik at de har en én mot én-mapping.`}</p>
    <img src={lightdarkmodecontrast} className="darkmode__image" width="100%" alt="" />
    <Heading4 as="h2" mdxType="Heading4">Eksempel på komponent</Heading4>
    <img src={componentExample} className="darkmode__image" width="100%" alt="eksempel på dark-mode, contrast og light" style={{
      marginBottom: '-8px',
      marginTop: '8px'
    }} />
    <img src={componentExampleDark} className="darkmode__image" width="100%" alt="" />
    <Heading4 as="h2" mdxType="Heading4">Eksempel på illustrasjon</Heading4>
    <img src={illustrationExample} className="darkmode__image" width="100%" alt="eksempel på illustrasjoner i ulike modes" style={{
      marginBottom: '-8px',
      marginTop: '8px'
    }} />
    <img src={illustrationExampleDark} className="darkmode__image" width="100%" alt="" />
    <Heading4 as="h2" mdxType="Heading4">Eksempel på Entur-app</Heading4>
    <img src={lightdarkmode} className="darkmode__image" width="100%" alt="" style={{
      marginTop: '8px'
    }} />
    <h2 {...{
      "id": "bruk-i-figma"
    }}>{`Bruk i Figma`}</h2>
    <p>{`Her er et eksempel på hvordan Dark mode-farger kan implementeres med automatisk lys/mørk fargebytting.`}</p>
    <BaseCardDesignEntur title="Eksempel aktivere automatisk dark mode i Figma" subText="Ved å lage et «frame» som inneholder designløsningen, og deretter slå på alle fargetokens til «dark» som eksempelet viser, kan automatisk fargebytting bli aktivert. Dette gjør det mulig å flytte designløsningen mellom lys og mørk bakgrunn med automatisk fargebytting." mdxType="BaseCardDesignEntur">
  <img src={darkmodedemo} alt="eksempel på darkmode i Enturappen" className="designentur-base-card__image" />
    </BaseCardDesignEntur>
    <h2 {...{
      "id": "bruk-i-kode"
    }}>{`Bruk i kode`}</h2>
    <p>{`Alle designsystemets komponenter fra komponentbiblioteket bruker fargetokens som støtter light, dark og contrast.`}</p>
    <p>{`For å aktivere dark-mode på designsystemets komponenter kan du inkludere attributtet data-color-mode på html-elementet. Dette attributtet vil stå som en indikator for hvilken mode som skal aktiveres, om det er light eller dark.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html data-color-mode="dark">
<html data-color-mode="light">
`}</code></pre>
    <p>{`For å bytte mellom light- og dark-modus, trenger du en måte å endre verdien til data-color-mode-attributtet dynamisk. Dette er avhengig av ditt valg av rammeverk eller bibliotek.`}</p>
    <p>{`Om deler av din løsning ikke bruker designsystemets komponenter og du ønsker å støtte darkmode kan du bruke tokens fra `}<inlineCode parentName="p">{`base.css`}</inlineCode>{` , `}<inlineCode parentName="p">{`base`}</inlineCode>{`-stilsettet støtter dark-mode. Sjekk ut `}<a parentName="p" {...{
        "href": "/tokens/fargetokens/oversikt-farger#base"
      }}>{`oversikt over base-tokens`}</a>{`.
Hvis `}<inlineCode parentName="p">{`base`}</inlineCode>{` ikke dekker ditt behov kan du lage et eget internt stilsett, for eksempel:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`/* Eksempel intern styles.scss */
@use '@entur/tokens/dist/semantic.scss' as semantic;

/* Stiler for light-modus */
[data-color-mode='light'],
:root {
  --my-background-color: semantic.$fill-background-standard-colorless;
}
/* Stiler for dark-modus */
[data-color-mode='dark'] {
  --my-background-color: semantic.$fill-background-standard-dark;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      